import styled from "styled-components";
import { defaultBreakpoints } from "styled-media-query";

export const Wrapper = styled.div`
  grid-template-columns: 1fr 1fr;
  display: grid;
  padding-bottom: 31px;
  column-gap: 1rem;
  @media (max-width: ${defaultBreakpoints.medium}) {
    grid-template-columns:1fr 1fr;
    padding-bottom: 20px;
    row-gap: 12px;  
  }

  @media (max-width: ${defaultBreakpoints.small}) {
    grid-template-columns: 1fr;
    padding-bottom: 0;
  }

  .tab {
    max-width: 350px;
    overflow:hidden;

    @media (max-width: ${defaultBreakpoints.small}) {
      max-width: 150px;
      overflow: hidden;
    }
  }
`;