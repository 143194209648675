import { FC } from "react";
import { Wrapper } from "./MultiX2Articles.styled";
import Item from "Components/Item";
import { SectionComponent } from "../types";
import Title from "../../../../Components/Title";
import {getCropData} from "../getCropData";

const MultiX2Articles: FC<SectionComponent> = ({ data }) => {
  const { article_list_1, article_list_2 } = data;

  return (
    <>
      <Wrapper>
        {article_list_1.map((article, index) => (
          <Item
            key={index}
            size={2}
            mpu={false}
            isHero={false}
            article={article}
            isMulti={true}
            cropData={getCropData(article, "twobyone")}
          />
        ))}
        {article_list_2.map((article, index) => (
          <Item
            key={index}
            size={2}
            mpu={false}
            isHero={false}
            article={article}
            isMulti={true}
            cropData={getCropData(article, "twobyone")}
          />
        ))}
      </Wrapper>
    </>
  );
};

export default MultiX2Articles;
